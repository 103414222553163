import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { getRegisterError, getRegistering, getRegisterSuccess, Register, RemoveErrors } from '../../store';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { SetTitle } from '../../../store';
import { DynamicFormField } from '@teamfoster/dynamic-forms';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
})
export class RegisterComponent implements OnInit {
  registering$: Observable<boolean> = of(false);
  registerSuccess$: Observable<boolean> = of(false);
  registerError$: Observable<any> = of();

  prefix = 'registreren';
  formValid = false;
  private dict = new FromDictionaryPipe(this.lang);

  formFields: DynamicFormField[] = [];

  constructor(
    private fb: FormBuilder,
    private lang: LanguageService,
    private store: Store,
    private cd: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.store.dispatch(RemoveErrors());
    this.formFields = [
      {
        inputType: 'email',
        label: this.dict.transform(`${this.prefix}-email-label`),
        name: 'email',
        validators: [Validators.email, Validators.required],
        autocomplete: 'email',
        placeholder: this.dict.transform(`${this.prefix}-email-placeholder`),
        fieldType: 'input',
        order: 0,
      },
      {
        inputType: 'text',
        label: this.dict.transform(`${this.prefix}-voornaam-label`),
        name: 'firstName',
        validators: [Validators.required],
        autocomplete: 'given-name',
        placeholder: this.dict.transform(`${this.prefix}-voornaam-placeholder`),
        fieldType: 'input',
        order: 1,
      },
      {
        inputType: 'text',
        label: this.dict.transform(`${this.prefix}-achternaam-label`),
        name: 'lastName',
        validators: [Validators.required],
        autocomplete: 'family-name',
        placeholder: this.dict.transform(`${this.prefix}-achternaam-placeholder`),
        fieldType: 'input',
        order: 1,
      },
      {
        inputType: 'password',
        label: this.dict.transform(`${this.prefix}-wachtwoord-label`),
        name: 'password',
        validators: [Validators.required],
        autocomplete: 'new-password',
        hint: this.dict.transform('registreren-wachtwoord-hint'),
        fieldType: 'input',
        placeholder: this.dict.transform(`${this.prefix}-wachtwoord-placeholder`),
        order: 1,
        cssClass: 'mb-3',
      },
      {
        inputType: 'password',
        label: this.dict.transform(`${this.prefix}-wachtwoord-bevestigen-label`),
        name: 'confirmPassword',
        validators: [Validators.required],
        autocomplete: 'new-password',
        placeholder: this.dict.transform(`${this.prefix}-wachtwoord-bevestigen-placeholder`),
        fieldType: 'input',
        order: 1,
      },

      {
        fieldType: 'checkbox',
        label: '',
        checkboxLabel: this.dict.transform(`${this.prefix}-voorwaarden-label`),
        name: 'consent',
        validators: [Validators.requiredTrue],
        order: 1,
      },
    ];
    this.registerSuccess$ = this.store.select(getRegisterSuccess);
    this.registering$ = this.store.select(getRegistering);
    this.registerError$ = this.store.select(getRegisterError).pipe(
      tap(error => {
        if (!error?.ok) {
          window.scrollTo(0, 0);
        }
      })
    );
    this.store.dispatch(
      SetTitle({ title: `${this.dict.transform(this.prefix + '-titel')} - ${this.dict.transform('meta-titel-suffix')}` })
    );
  }

  handleSubmit(registerData: any) {
    const newPasswordIsValid = registerData?.password.value === registerData?.confirmPassword.value;

    if (newPasswordIsValid) {
      this.store.dispatch(Register({ registerData }));
    }
  }
}
