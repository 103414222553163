<app-auth-layout [title]="prefix + '-titel' | fromDictionary">
  <fstr-dynamic-form form [appearance]="'fill'" [config]="formFields" (formSubmit)="handleSubmit($event)" [showSubmit]="false">
    <app-alert
      slot="form-alert"
      *ngIf="error$ | async as error"
      [status]="'danger'"
      [message]="error?.error?.error || (getErrorMessage(error?.error) | fromDictionary)"
      class="d-block mb-3"
    ></app-alert>

    <div slot="form-actions">
      <button type="submit" class="button button--primary" [disabled]="signingIn$ | async">
        <span>{{ prefix + '-formulier-verzenden-knop-tekst' | fromDictionary }}</span>
      </button>
    </div>
  </fstr-dynamic-form>

  <ng-container footer>
    <div class="type-body-sm mt-4">
      <!--<span class="d-inline-block">
        <span class="d-inline-block me-2">{{ prefix + '-registreren-prefix-knop' | fromDictionary }}</span>
        <a class="me-1 d-inline-block" [routerLink]="['/', 'account', 'registreren']">
          {{ prefix + '-registreren-knop-tekst' | fromDictionary }}
        </a>
        |
      </span>-->
      <a class="d-inline-block fw-bold" [routerLink]="['/', 'account', 'wachtwoord-vergeten']">
        <span>{{ prefix + '-wachtwoord-vergeten-knop-tekst' | fromDictionary }}</span>
      </a>
    </div>
  </ng-container>
</app-auth-layout>
