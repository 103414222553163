<section class="auth-layout">
  <app-header [title]="title" [centerLeftColumn]="true" [cssClasses]="'h-100'">
    <!--<span class="fs-body-md mb-0 auth-layout__subtitle" header>{{ 'account-authenticatie-subtitel' | fromDictionary }}</span>-->
    <div body>
      <article class="card shadow-sm p-4 auth-layout__card">
        <ng-content select="[form]"></ng-content>

        <footer #footer [class.mt-3]="footer?.children?.length">
          <ng-content select="[footer]"></ng-content>
        </footer>
      </article>
    </div>
  </app-header>
</section>

<app-background></app-background>
